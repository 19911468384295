:root {
  --clickup-initial-height: 50px;
  --clickup-open-height: calc(100% - 85px);
}

.clickup {
  position: absolute;
  position: fixed;
  left: 0;
  top: calc(100% - var(--clickup-initial-height));
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  transition: top 200ms ease-out;
  z-index: 5;
}

.clickup.open {
  top: calc(100% - var(--clickup-open-height));
}

.clickup-bar {
  background-color: var(--ion-color-secondary);
  flex: 0 0 var(--clickup-initial-height);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.clickup-bar > div {
  text-align: right;
  color: var(--ion-color-primary);
  font-weight: normal;
}

.clickup-caret {
  height: 20px;
  width: 25px;
  background: transparent url(../../assets/images/caret-up-white.svg) no-repeat 5px 50%;
}
.clickup-label{
  color: var(--ion-color-secondary-contrast) !important;
}
.clickup.open .clickup-caret {
  background-image: url(../../assets/images/caret-down-white.svg);
}

.clickup-content {
  flex: 1 1 auto;
  background-color: var(--okx-background-color);
  position: relative;
}
