.redeem-gift-voucher-component-wrapper {
    display: flex;
    align-items: flex-end;
  }
  .redeem-gift-voucher-component-wrapper ion-button {
    margin-left: 10px;
    margin-bottom: 12px;
  }
  .redeem-gift-voucher-component-wrapper > div {
    flex: 1;
  }
  