.route-gift-vouchers .swiper-slide {
    text-align: start;
  }
  .default-padding {
    padding: 0 var(--ion-padding);
  }
  .voucher-input-wrapper {
    display: flex;
    padding: 0 var(--ion-padding);
  }
  /* .voucher-input-wrapper input,
  .send-voucher-wrapper input {
    padding-left: 0 !important;
    border-bottom: 1px solid var(--ion-color-primary) !important;
    margin-right: 20px;
    height: 100%;
  } */
  /* .recieve-voucher-wrapper,
  .send-voucher-wrapper,
  .order-summary-wrapper,
  .sent-gift-vouchers-wrapper,
  .success-gift-voucher {
    margin-top: var(--ion-padding);
  } */
  .success-gift-voucher {
    margin: 50px;
    display: flex;
    flex-direction: column;
  }
  /* .sent-gift-vouchers-wrapper {
    display: flex;
    flex-direction: column;
    height: calc(100% - var(--ion-padding));
  } */
  .select-amount-wrapper {
    background-color: var(--ion-color-light);
    text-align: center;
    position: relative;
    padding: var(--ion-padding);
    margin: 20px 0 50px 0;
  }
  .select-amount-wrapper .actions {
    position: absolute;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 var(--ion-padding);
  }
  .select-amount-wrapper .actions ion-button {
    flex: 1;
    margin-right: var(--ion-padding);
  }
  .select-amount-wrapper .actions ion-button:last-child {
    margin-right: 0;
  }
  /* .route-gift-vouchers .send-voucher-wrapper .normal-text {
    display: block;
    margin-bottom: 20px;
  } */
  .send-voucher-btn {
    width: 100%;
    margin-top: var(--ion-padding);
  }
  
  .gift-voucher-order-content {
    display: flex;
    align-items: center;
  }
  .gift-voucher-order-content > ion-icon {
    height: 25px;
    width: 25px;
  }
  .gift-voucher-order-content-summary {
    flex: 1;
    margin: 0 10px;
  }
  .gift-voucher-total {
    border: 2px solid var(--ion-color-primary);
    border-left: 0;
    border-right: 0;
  }
  
  .route-gift-vouchers .link.underlined {
    font-size: var(--ion-font-size);
    margin: 5px 0;
  }
  
  /* personal message modal */
  
  .personal-message-modal-button {
    display: flex;
    align-items: flex-end;
    flex: 1;
  }
  .personal-message-modal-button ion-button {
    width: 100%;
  }
  .personal-message-modal .modal-classic-content {
    display: flex;
    flex-direction: column;
    border-radius: 0;
  }
  .personal-message-modal .modal-wrapper{
    border-radius: 0 !important;

  }
  .web .personal-message-modal .modal-classic-content {
    justify-content: flex-start !important;
  }
  .special-instructions-wrapper ion-icon {
    margin-left: 10px;
  }
  .special-instructions-wrapper ion-button {
    height: fit-content;
    margin: 0;
  }
  .personal-message-modal .modal-wrapper {
    border-radius: 0;
  }
  .web .personal-message-modal .modal-wrapper {
    border-radius: var(--border-radius);
  }
  .personal-message-modal {
    --height: 100% !important;
    --width: 100% !important;
  }
  .web .personal-message-modal {
    --height: 85% !important;
    --width: 500px !important;
  }
  
  .personal-message-wrapper,
  .gift-vouchers-date-wrapper {
    background-color: var(--ion-color-light);
    padding: var(--ion-padding);
  }
  /* .gift-vouchers-date-field {
    border: none;
    border-bottom: 1px solid var(--ion-color-primary);
    background-color: transparent;
    width: 100%;
  }
  .gift-vouchers-date-field:focus,
  .gift-vouchers-date-field:active,
  .gift-vouchers-date-field:focus-visible {
    outline: none;
  } */
  .gift-voucher-checkout,
  .gift-voucher-succes {
    padding: var(--ion-padding);
  }
  .success-image {
    display: block;
    margin: 0 auto;
  }
  
  .gift-voucher-user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 20px;
    flex: 1;
    overflow: hidden;
  }
  .gift-voucher-date {
    font-size: 9px;
  }
  /* .gift-voucher-history-list {
    padding: 10px var(--ion-padding);
    height: 75%;
    overflow: auto;
  } */
  .gift-voucher-history-list ion-item {
    cursor: pointer;
  }
  
  .route-gift-vouchers .ion-padding ion-header {
    display: none;
  }
  .route-gift-vouchers .ion-padding .ion-padding {
    max-height: 100% !important;
    margin: 0 !important;
  }
  
  .gift-voucher-summary-table > div {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
  .gift-voucher-summary-table > div:first-child {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--ion-color-light);
    padding-top: 0;
  }
  .gift-voucher-summary-table > div:last-child {
    padding-bottom: 0;
  }
  .help-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 30px;
  }