

.history-details ion-grid {
  padding-inline-start: 0;
  padding-inline-end: 0;
}

.history-details ion-row {
  /* border-bottom: 1px solid var(--okx-border-color); */
  color: var(--ion-color-dark);
  font-weight: normal;
}

.history-details .okx-box-header-grid ion-row {

}

/* .history-details .okx-box-header-grid ion-row ion-col:nth-of-type(2) {
  display: flex;
  justify-content: flex-end;
} */


.history-details .okx-box-header-grid ion-row ion-col:nth-of-type(1) ion-text {
  white-space: nowrap;
}

.history-details .no-border,
.history-details ion-row.no-border- {
  border-bottom: 0 none;
}

.history-details .okx-box-footer {
  border-top: 2px solid var(--okx-border-color);
  padding-left: 0;
  margin-left: 0;
  padding-right: 0;
  margin-right: 0;
}

/* .history-details .okx-box-footer ion-row:not(ion-row:last-of-type) {
	border-bottom: 0 none;
}

.history-details .okx-box-header-grid ion-row:last-of-type {
	border: 0;
} */

.history-details .okx-box-footer ion-row {
  border-bottom: 0 none;
}

/* .history-details .okx-box-header-grid ion-row:nth-of-type(1n) {
	border: 0;
} */

#main > ion-content.route-history-details .order-content-wrapper:last-of-type {
  border-bottom-width: 1px;
}

#main > ion-content.route-history-details .okx-box-header-grid:last-of-type {
  border-bottom-width: 0px;
}

#main
  > ion-content.route-history-details
  .order-summary-sidebar
  .order-content-wrapper
  .subtotal-bordered-bottom {
  border-top: 1px solid var(--okx-border-color);
}

#main > ion-content.route-history-details > .ion-padding .okx-box {
  padding: 20px;
  padding-top: 30px;
}

#main > ion-content.route-history-details > .ion-padding .okx-box-header {
  padding-bottom: 15px;
}



.web #main > ion-content.route-history-details {

}



/* .web #main > ion-content.route-history-details > .ion-padding > .absolute-content.history-details {
  background: none;
} */

/* .web
  #main
  > ion-content.route-history-details
  > .ion-padding
  > .absolute-content.history-details:after {
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
} */

.web
  #main
  > ion-content.route-history-details
  > .ion-padding
  > .absolute-content.history-details
  > div.scrollable-y {
  height: 100%;
}

.web .history-details .okx-box,
.web .history-details .okx-box-header {
  border-color: var(--okx-border-color);
  border-width: 1px;
}

.web .history-details .okx-box-header p.light-text br {
  display: none;
}

.web .history-details .okx-box-header p.light-text {
  color: var(--ion-color-dark);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 7px 0;
}

.web .history-details .okx-box-header p.light-text ion-text:nth-of-type(odd) strong {
  font-weight: normal;
}

.web .history-details .okx-box-header p.light-text ion-text:nth-of-type(even) {
  text-align: right;
  font-weight: bold;
}

@media screen and (min-width: 1441px) {
  .web #main > ion-content.route-history-details > .ion-padding {
    min-height: 65%;
  }
}

.web .history-details{
  padding: 0;
}
.history-details{
  padding-top: 0 !important;
}
.web .history-details>div{
  padding: var(--ion-padding);
}