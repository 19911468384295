.web ion-content.route-delivery {
	--ion-color-base: transparent;
	--background: transparent;
}

/* .ios .delivery-radio-button {
	border: 1px solid;
    background: var(--ion-color-white) !important;
    width: 20px;
    height: 20px;
    border-radius: 30px;
    padding-right: 0;
    margin-right: 10px;
} */
.delivery-additional-restaurant-information {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .delivery-additional-restaurant-information * {
    font-weight: bold;
  }