ion-modal.modal-classic {
  --width: 70%;
  --height: 50%;
}
ion-modal .ion-page{
  background-image: none !important;
}
.web ion-modal.modal-classic.quantity-modal {
  --height: 20%;
  --min-height: 210px;
}

.web ion-modal.modal-classic.quantity-modal .modal-classic-content {
  justify-content: center;
}
ion-modal.modal-classic.quantity-modal .modal-classic-content .incrementer-quantity-value{
  color: var(--ion-color-primary) !important;
}

ion-modal.modal-classic .modal-classic-wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 15px;
  max-height: 100%;
  background-color: var(--okx-background-color);
}

ion-modal.modal-classic .modal-classic-content {
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 30px 12px 12px;
}
ion-modal.modal-classic .modal-classic-closer{
  top: 35px !important;
  z-index: 10;
}
ion-modal.modal-classic .modal-classic-closer ion-icon{
height: 25px;
width: 25px;
}
ion-modal.modal-classic:not(.allergen-modal):not(.apply-points-modal)
  .modal-classic-content
  .absolute-content {
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-left: 0;
  padding-right: 0;
  
}

ion-modal.modal-classic .modal-classic-closer {
  position: absolute;
  top: 0;
  right: var(--ion-padding);
  cursor: pointer;
}

ion-modal.modal-classic .modal-classic-header,
ion-modal.modal-classic .modal-classic-action {
  flex: 0 1 auto;
}

ion-modal.modal-classic .modal-classic-header {
  padding: 15px 5px;
}

ion-modal.modal-classic .modal-classic-header h3 {
  font-size: var(--ion-font-size);
  font-weight: bold;
  margin: 0;
}
