.incrementer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--ion-color-primary) !;
}

.incrementer ion-button {
	--border-radius: 50%;
	--color: var(--ion-color-primary);
  color: var(--ion-color-primary);
	flex: unset;
}

.incrementer ion-button * {
  color: var(--ion-color-primary);
}

.incrementer-decrease {
  --background: var(--ion-color-secondary);
}

.incrementer-decrease,
.incrementer-incease {
  flex: 0 0 var(--okx-incrementer-button-width);
  height: var(--okx-incrementer-button-width);
  --padding-start: 0;
  --padding-end: 0;
  --border-width: 1px;
  --border-style: none;
  font-size: 20px;

  --color: var(--ion-color-secondary);
  margin: 0;
}

.incrementer-quantity {
  flex: 1 1 auto;
  padding-left: var(--okx-incrementer-button-width);
  padding-right: var(--okx-incrementer-button-width);
}

.incrementer-quantity-solo {
  flex: 0 0 45px;
  text-align: center;
}

.incrementer-quantity-value {
  font-weight: bold;
  color: var(--ion-color-primary-contrast) !important;

}

.incrementer-note {
  font-size: var(--okx-small-text-font-size);
  color: var(--ion-color-gray);
}

.web .incrementer-decrease,
.web .incrementer-incease {
  --color: var(--ion-color-primary);
}