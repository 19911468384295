.route-order .segment-holder {
  padding: 0;
}

.order-categories {
  flex: 0 0 auto;
}

.order-categories h2 {
  padding: 0 25px;
}

.order-categories-inner {
  display: flex;
  flex-flow: row;
  width: 100%;
  overflow-y: auto;
}


.category-button {
  --color: var(--ion-color-black);
  --border-radius: 14px;
  margin: 0 0 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--ion-color-light);
  text-transform: capitalize !important;
  height: 30px !important;
}
.order-categories-inner::-webkit-scrollbar {
  visibility: hidden;
}
.category-button.active {
  border-bottom: 2px solid var(--ion-color-secondary);
  /* --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-white);
  --background-activated: var(--ion-color-primary); */
}
.grid-menus .order-list-items {
  height: 100%;
  overflow: hidden;
}
.grid-menus .order-content {
  flex: 1 1 100%;
  overflow: hidden;
  padding: 0 var(--ion-padding);
}
.order-list-items {
  height: 100%;
  overflow: unset;
}
 .order-content {
  flex: 1 1 100%;
  overflow: auto;
  padding: 0 var(--ion-padding);
}

.web .order-list-items {
  overflow: unset;
}
.order-list-items > .list-md {
  padding-bottom: 0;
  position: relative;
}

.order-list-items ion-item {
  --min-height: 20px;
  --inner-padding-end: 0;
  cursor: pointer;
}

.order-list-item {
	display: flex;
	width: 100%;
	justify-content: space-around;
	align-items: center;
	padding: 10px;
	background-color: var(--okx-box-wrapper-background);
	margin-bottom: 10px;
	border-radius: var(--okx-box-radius);
	min-height: 70px;
	align-items: center;
	justify-content: space-between;
}

.order-list-item > div {
  flex: 0 1 auto;
  margin-left: 16px;
}
.order-list-item > div:first-child {
  margin-left: 0;
  display: flex;
  flex-direction: column;
}
/* .order-list-item > div:nth-child(2) {
  flex: 1 1 auto;
} */
.order-list-item > div:nth-child(3) {
  text-align: right;
  margin-right: 10px;
}
.item-content div {
  color: var(--ion-color-gray);
}
.item-content > h5 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
  color: var(--ion-color-dark);
}
.item-content p {
  margin: 3px 0 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.item-image {
  background: none transparent no-repeat 50% 50%;
  background-size: 100% auto;
  width: 50px;
  padding-top: 100%;
}
.item-price > span:first-child {
  color: var(--ion-color-dark);
  font-size: 13px;
  font-weight: bold;
}
.item-price > span.original-price {
  text-decoration: line-through;
  font-size: 0.9em;
}
.order-list-header {
  padding: 25px;
  margin-bottom: 10px;
}

.order-list-header ion-label {
  font-size: 25px;
  font-weight: 600;
  text-transform: initial;
}

.order-sublist-header {
  padding-left: 0;
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  z-index: 2;
  --color: var(--okx-default-text-color);
  background-color: var(--okx-background-color);
}

.order-sublist-header.empty {
  /* max-height: 0;
	height: 0px;
	overflow: hidden;
	min-height: 0; */
  display: none;
}

.order-sublist-header ion-label {
  font-size: 20px;
  font-weight: bold;
  text-transform: initial;
  font-family: var(--ion-font-family-bold);
  color: var(--okx-default-text-color);
  margin-top: 0;
}

.order-intro-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  background: var(--ion-color-light-shade);
}

.order-intro-image-wrapper {
  flex: 1 1 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ion-color-black);
  background: var(--ion-color-light-shade);
  width: 100%;
}

.order-intro-content {
  height: 250px;
  padding: 25px;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  background: var(--ion-color-medium-contrast);
}

.order-intro-content p {
  color: var(--ion-color-black);
  text-align: center;
  padding: 0 10px;
  font-size: 14px;
  margin: 25px 0;
}

.order-intro-modal > .modal-wrapper {
  height: 320px;
  width: 270px;
  border-radius: 15px;
}

.order-intro-modal > .modal-wrapper > .ion-page {
  min-width: 100%;
  padding: 15px 25px;
}

.order-intro-modal > .modal-wrapper > .ion-page ion-icon {
  position: absolute;
  right: 15px;
  font-size: 35px;
}

.order-intro-modal > .modal-wrapper > .ion-page h2 {
  font-size: 20px;
  margin-top: 4px;
}

.order-intro-modal > .modal-wrapper > .ion-page .order-intro-modal-inner {
  flex: 1 1 0%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}

.location-select {
  --padding-start: 0;
  margin-top: 5px;
  border-bottom: 1px solid var(--ion-color-medium);
}

.popover-content ion-item ion-label {
  margin-left: 10px;
}

.pickup-time {
  margin-top: 5px;
  border-bottom: 1px solid var(--ion-color-medium);
}

.item-content {
  margin-right: auto;
}

.item-image {
  margin-left: auto;
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;
}
.app {
  display: grid;
  grid-template-columns: 20% 80%;
}
.navbar {
  padding: 0.5rem;
}
.navbar .nav-link {
  padding: 0.5rem;
}
.header {
  height: 80px;
  overflow: auto;
  background: #aaa;
}
.container {
  height: 500px;
  background: #ddd;
}
.gap {
  height: 500px;
}
.gap.short {
  height: 250px;
}
.gap.tall {
  height: 1000px;
}
.container.relative {
  overflow-y: auto;
}

.view-order-button {
  position: fixed;
  bottom: 15px;
  text-align: center;
  z-index: 3;
  left: 14px;
  right: 14px;
}

.segment-holder.grid-menus {
  overflow: auto !important;
}
.segment-holder.grid-menus .order-categories {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.order-categories-tiles-wrapper {
  width: 100%;
  margin-bottom: 20px;
}
.order-categories-two-columns-tiles-wrapper,.order-list-items ion-list > ion-item {
  width: calc(50% - 10px);
  margin-bottom: 20px;
}
.order-categories-two-columns-tiles-wrapper:nth-child(odd),.order-list-items ion-list > ion-item:nth-child(odd){
margin-right: 10px;
}
.order-categories-two-columns-tiles-wrapper:nth-child(even),.order-list-items ion-list > ion-item:nth-child(even){
  margin-left: 10px;
}
.order-categories-tile {
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  background-blend-mode: darken;
  text-align: center;
  font-weight: bold;
  text-transform: capitalize;
  /* padding: 20px; */
  
}
.grid-menus .order-categories {
  padding: 0;
}
.route-order > .no-padding > .segment-holder.grid-menus > .order-categories > ion-text > span {
  padding-left: 30px;
  padding-bottom: 10px;
}
.grid-menus .order-categories-tile-title {
  color: var(--ion-color-primary);
  font-size: 22px;
  text-transform: capitalize;
}
.grid-menus .order-list-item {
  display: grid;
  margin: 0;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'image image'
    'desc desc'
    'price icon';
  align-items: flex-end;
  padding: 0;
  border: none;
}
.grid-menus .order-list-items ion-item {
  --border-style: unset;
  background-color: var(--ion-color-primary-contrast);
  width: calc(50% - 10px);
  margin-bottom: 20px;
  box-shadow: 0 0 6px var(--ion-color-dark);
  border-radius: 10px;
}
/* .grid-menus .order-list-items ion-item:nth-of-type(odd){
  margin-right: 10px;
}
.grid-menus .order-list-items ion-item:nth-of-type(even){
  margin-left: 10px;
} */
.grid-menus .item-content {
  margin: 0;
  grid-area: desc;
}
.grid-menus .item-content > div {
  display: none;
}
.grid-menus .item-price {
  grid-area: price;
  margin: 0;
}
.grid-menus .item-icon-wrapper {
  grid-area: icon;
}
.grid-menus .item-icon-wrapper ion-icon {
  height: 20px;
  width: 20px;
  opacity: 0.4;
}
.grid-menus .item-price > span:first-child {
  font-size: 18px;
  font-weight: 400;
}
.grid-menus .default-height {
  display: none;
}
.grid-menus .item-content > h5 {
  font-size: 18px;
  font-weight: bolder;
  font-weight: 900;
  letter-spacing: normal;
  min-height: 50px;
  text-align: start;
}
.grid-menus .order-list-item > div:first-child {
  flex: unset;
}
.grid-menus .item-image-wrapper {
  width: 100%;
  margin: 0;
  grid-area: image;
}
.grid-menus .item-image {
  width: 100%;
  height: 150px;
  padding: 0;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.2);
  background-size: cover;
}

.grid-menus .category-title {
  margin: 10px 10px 10px 20px;
  font-size: 20px;
  font-weight: bold;
}
.grid-menus .order-sublist-holder {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.grid-menus .order-list-items ion-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 var(--ion-padding);
  margin-bottom: 50px;
}
.no-items-message{
  margin: var(--ion-padding);
  display: block;
}
@media (max-width: 700px) {
  .app {
    grid-template-columns: unset;
  }
}
.grid-order-list-item{
  padding-bottom: 5px!important;
}
.grid-order-list-item .item-price, .grid-order-list-item .item-content{
  padding: 5px 5px 0 !important;
}
.grid-order-list-item .item-content .strong-text{
  display: block;
  line-height: 1.2;
}