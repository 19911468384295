.table-number-input {
  --padding-start: 0;
  border-bottom: 1px solid;
  border-bottom-color: var(--okx-border-color);
  margin-left: 30px;
}

#main > ion-content.route-order-to-table .select-picker-label,
#main > ion-content.route-order-to-table .time-picker-label {
  color: var(--ion-color-primary);
  font-size: 12px;
  font-family: primary-bold;
}

.web ion-content.route-order-to-table {
  background-position: 100% 0;
  background-repeat: no-repeat;
}

.click-collect-wrapper {
  margin: 0 0 var(--ion-padding) ;
  height: 100%;
}

.click-collect-selectors {
  margin: 50px 0 40px 0;
}

.click-collect-layout {
  height: 100%;
}

.no-padding {
  height: 100%;
}
.click-collect-dialog-header{
  padding: 0 var(--ion-padding);
}
.web .route-order-to-table .click-collect-wrapper {
  margin: 0 0 var(--ion-padding) 0;
  height: fit-content;
}
/* .web .click-collect-button {
  position: sticky;
  width: unset;
  margin: unset;
  left: 0;
  padding: 0 var(--ion-padding);
} */

.web .no-padding {
  height: fit-content;
}
.route-order-to-table .click-collect-layout{
  height: unset !important;
}

.table-number {
  display: flex;
  padding: var(--ion-padding) 0;
  flex-direction: column;
  align-items: flex-start
}
/* .table-number .mbsc-fr{
  width: 100%;
} */
.route-order-to-table .click-collect-dialog-content{
  padding: 0;
}
.shadowed-box-light{
  margin: 0 var(--ion-padding);
  pointer-events: none;
}
.table-number .mbsc-select-input.mbsc-control{
  display: none;
}

.web .route-order-to-table .click-collect-layout{
  overflow: auto !important;
  padding-bottom: var(--ion-padding) !important;
}
.order-to-table-picker-wrapper{
  border: 1px solid var(--ion-color-light);
  padding: 0 2px;
  border-radius: var(--okx-box-radius);
  overflow: hidden;
  margin: auto;
}
.route-order-to-table .ion-checkbox{
  background-color: var(--ion-color-secondary);
}
.route-order-to-table .ion-padding > .absolute-content{
  padding: 0;
}
.route-order-to-table .ion-padding {
  padding-top: 0px;
}

.order-to-table-wrapper{
  height: 100%
}
.order-to-table-wrapper  .click-collect-dialog-header, .order-to-table-wrapper .shadowed-box-light{
  margin: 0 !important;
  padding: 0 !important;
}
.table-dialog-header {
  padding: var(--ion-padding) 0;
}