ion-button.link.link-centered {
  margin: 0 auto;
}


.route-checkout .absolute-content {
  bottom: auto;
}

.paymentRequestButton {
  margin-top: 20px;
  padding: 0 2px;
}

.paymentRequestButtonCardAdd {
  margin-bottom: 20px;
  padding: 0 2px;
}

.link-lefted {
  width: 100%;
  text-align: left;
}

.pay-divider {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--ion-color-primary);
  line-height: 0.1em;
  margin: 30px 0 30px;
}

.pay-divider span {
  background: var(--okx-background-color);
  padding: 10px;
  line-height: 0;
}
.ion-radio-checkout .ion-color-primary {
  --ion-color-base: var(--ion-color-primary) !important;
}

.delivery-time-modal .modal-wrapper {
  height: 70%;
  width: 90%;
}

.web .delivery-time-modal .modal-wrapper {
  width: 500px;
}

.delivery-time-modal .mbsc-select-input.mbsc-control {
  display: none !important;
}
.checkout-card {
  margin: 0 0.5px 15px;
}
.checkout-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.no-cards {
  display: flex;
  text-align: center;
  place-items: center;
  border: dashed 2px;
  opacity: 0.4;
  border-radius: var(--ion-card-radius);
  padding: calc(var(--ion-padding) / 2);
}
.no-cards ion-icon {
  height: 40px;
  width: 40px;
  margin-right: calc(var(--ion-padding) / 2);
}