.web #main > ion-header.route-login {
	z-index: 100;
}

.web #main > ion-content.route-login > .ion-padding > .absolute-content {
	/* padding: 48px 64px 48px; */
	overflow-y: auto;
}

.web #main > ion-content.route-login > .ion-padding {
	width: var(--okx-bsmodal-width-md);
}
.route-login .absolute-content > div {
	display: flex;
	flex-direction: column;
}
