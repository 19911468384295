.order-header-content > * {
	display: flex;
	align-items: center;
}
.order-header-content svg {
	height: 25px;
	width: 25px;
	margin-right: 15px;
}

.order-header-content > div:nth-child(2) {
	justify-content: space-between;
	margin-top: 10px;
}