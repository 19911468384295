.route-dashboard .primary-toolbar {
  background-color: var(--ion-color-primary-contrast);
}

.dashboard-header {
  padding: calc(var(--ion-padding)) var(--ion-padding) 170px var(--ion-padding);
  background-color: var(--ion-color-primary-contrast);
  position: relative;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.dashboard-header .title {
  text-transform: uppercase;
  font-size: var(--okx-big-label-font-size);
}
.dashboard-header .normal-text {
  font-size: var(--okx-subtitle-font-size);
  font-family: primary-bold;
}
.dashboard-card:last-of-type {
  margin: 0;
}
.dashboard-card {
  height: 150px;
  border-radius: var(--okx-box-radius);
  background-position: center;
  background-size: inherit;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  padding: 5px 20px;
  display: flex;
  align-items: flex-end;
}

.dashboard-cards-wrapper {
  padding: var(--ion-padding);
  margin-top: -140px;
  background-color: var(--okx-background-color);
  background-image: url('../../assets/images/backgrounds/dashboard-bg.png');
  background-size: 110%;
  overflow: hidden;
  height: inherit;
}
.dashboard-card .subtitle {
  color: var(--ion-color-white);
  font-family: primary-bold !important;
  z-index: 5;
}
.dashboard-card > * {
  z-index: 5;
  font-size: var(--okx-subtitle-font-size);
}
/* .page-dashboard{
  background-color: var(--ion-color-primary);
} */
ion-content.route-dashboard::before {
  position: fixed;
  content: '';
  top: 0;
  background-color: var(--ion-color-secondary);
  height: 60%;
  width: 100%;
  z-index: -1;
}
.dashboard-content {
  height: 100%;
  overflow: hidden;
}
.route-dashboard .no-padding {
  overflow: auto;
  background-color: var(--ion-color-primary-contrast);
  height: fit-content;
}
.dashboard-promo-bars-wrapper {
  width: 100%;
  z-index: 5;
  padding-top: 15px;
}
.route-dashboard .swiper-wrapper {
  height: 200px;
}
.route-dashboard .swiper-slide {
  min-height: unset;
}
.route-dashboard .swiper-slide img {
  width: 100%;
  object-fit: cover;
}
.banner-fullscreen-image-modal .modal-wrapper {
  --height: 100%;
  --width: 100%;
  border-radius: 0 !important;
}
.banner-fullscreen-image-modal .modal-classic-wrapper,
.banner-fullscreen-image-modal .modal-classic-content {
  padding: 0 !important;
}
.banner-fullscreen-image-modal img {
  object-fit: cover;
  height: 100%;
}
