.card-add-brands {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 30px;
}

.card-add-brands .card-add-brand {
  /* width: 15%; */
  width: 35px;
}
