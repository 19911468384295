ion-modal.modal-classic.apply-voucher-modal {
  --width: 80%;
  --height: 45%;
  --border-radius: 10px;
}

.web ion-modal.modal-classic.apply-voucher-modal {
  --width: 500px;
  --height: 45%;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-wrapper {
  padding: 0;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-header {
  background-color: var(--ion-color-secondary);
  padding-left: 20px;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-header h3 {
  color: var(--ion-color-white);
  text-transform: uppercase;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-closer {
  color: var(--ion-color-white);
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-content {
  padding: 15px 15px;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-content > .flex-row-wrapper {
  height: 100%;
}

.apply-voucher-modal.voucher-modal-content {
  padding: 0 30px;
}

ion-button.box-button-color {
  --background: var(--ion-color-gray-tint);
}

ion-button.box-button-color-tertiary {
  --background: var(--ion-color-tertiary);
}
.apply-voucher {
  border: 5px solid var(--ion-color-secondary);
}
.apply-voucher-box,
.apply-voucher {
  height: 150px;
  margin-right: 2px;
}
.apply-voucher-box img,
.apply-voucher img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.web .apply-voucher,
.web .apply-voucher-box {
  cursor: pointer;
  margin: 20px 0 0 !important;
}
.apply-voucher-box .voucher-card-wrapper svg {
  fill: var(--ion-color-secondary);
}
.voucher-svg {
  height: 100%;
  width: 100%;
}

.apply-voucher-box,
.apply-voucher {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.apply-voucher-box .voucher-card-content,
.apply-voucher .voucher-card-content {
  width: auto;
}
.apply-voucher .voucher-card-wrapper {
  border-style: solid;
  border-width: 5px;
  border-color: #1770ff;
  width: 100%;
  height: 100%;
}
.apply-voucher-box .voucher-card-wrapper {
  width: 100%;
  height: 100%;
}
.voucher-icon {
  padding-left: inherit;
}
.voucher-card-content {
  display: -webkit-inline-box;
}
.vaucher-list {
  position: relative;
}

.redeem-voucher-field-wrapper {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
}
.redeem-voucher-field-wrapper >div {
  flex: 1;
  margin-right: 10px;
  margin-bottom: 0;
}
.redeem-voucher-field-wrapper ion-button{
  margin: 0;
}
