/* work */
ion-content div.restaurant-gradient {
  background-repeat: no-repeat;
  /* background-position: 100% 0; */
  background-position: 50% 100%;
  background-size: cover;
}

.table-order-layout {
  padding: 30px !important;
}

ion-content div.restaurant-gradient::before {
  content: '';
  display: block;
  height: 80%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 85%;
  z-index: 3;
  background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

/* don't work
.restaurant-gradient::before {
	background-image: linear-gradient(rgba(255,255,255,1), rgba(255,255,255,0));
}

.restaurant-gradient::before {
	content: '';
	display: block;
	position: absolute;
	left: 0; top: 0; right: 0; bottom: 0;
	z-index: 1;
	background-image: url(../../assets/images/clickAndCollect.png);
	background-repeat: no-repeat;
	background-position: 50% 0;
	background-size: 150% auto;
}
*/

.restaurant-card {
  border-radius: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  margin: 0;
}

.restaurant-card ion-card-content {
  padding: 30px;
  font-size: var(--ion-font-size);
}

.restaurant-card ion-card-content .restaurant-info-title {
  padding: 0 2px;
  font-weight: bold;
}

.restaurant-card ion-card-content .restaurant-info {
  padding: 15px 15px 30px;
  text-align: center;
}

.restaurant-card .flex-col-wrapper > .flex-min {
  display: flex;
  align-items: center;
}

.click-collect-pickers-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.3;
  height: 100%;
  width: 100%;
  z-index: 3;
}

.click-collect-dialog {
  position: fixed;
  margin: 0 auto;
  background-color: #fff;
  width: 70%;
  height: 50%;
  border-radius: 10px;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -45%);
  transition: all 0.3s ease-out;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  z-index: 999;
}

/* .web .click-collect-dialog {
	top: var(--okx-bsmodal-top);
	height: auto;
	left: unset;
	transform: unset;
	bottom: 80px;
} */

.click-collect-dialog.show-up {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, -50%);
  pointer-events: auto;
}
/*
.web .click-collect-dialog.show-up {
	transform: unset;
} */

.click-collect-dialog-layout {
  height: 100%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 5px 13px;
}

.click-collect-dialog.sc-ion-modal-md {
  border-radius: var(--border-radius);
  width: var(--width);
  min-width: var(--min-width);
  max-width: var(--max-width);
  height: var(--height);
  min-height: var(--min-height);
  max-height: var(--max-height);
  border-width: var(--border-width);
  border-style: var(--border-style);
  border-color: var(--border-color);
  background: var(--background);
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  overflow: var(--overflow);
  z-index: 10;
}



.click-collect-dialog-header h3 {
  font-size: 19px;
  font-weight: 700;
  margin: 0;
  text-transform: unset !important;
}

.click-collect-dialog-closer {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
}

.click-collect-dialog-closer ion-icon {
  font-size: 20px;
}

.click-collect-dialog-content {
  flex: 1 1 auto;
  padding: 12px;
  overflow-y: auto;
}

.click-collect-dialog-action {
  flex: 0 1 auto;
}

.click-collect-dialog-action ion-button {
  height: 36px;
}

.click-collect-dialog-content ion-label .sectiontitle {
  margin: 0;
  line-height: 18px;
}

#main > ion-content.route-click-and-collect .select-picker-label,
#main > ion-content.route-click-and-collect .time-picker-label {
  color: var(--ion-color-primary);
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.054em;
}

#main > ion-content.route-click-and-collect .mbsc-select-input.mbsc-control {
  display: none;
}

.delivery-option-label {
  position: absolute;
  top: 30px;
  left: 64px;
  right: 64px;
  text-align: center;
  z-index: 3;
}

.web ion-content.route-click-and-collect {
  background-position: 100% 0;
  background-repeat: no-repeat;
}



.web .restaurant-menu ion-item {
  --color: var(--ion-color-white);
  --background: var(--ion-color-black);
  margin-bottom: 20px;
  border-radius: var(--okx-small-radius);
}

.web .restaurant-card ion-card-content {
  padding: 0;
}

.web .restaurant-card ion-card-content .restaurant-info {
  padding: 15px 0 0;
}

.web .click-collect-dialog {
  width: var(--okx-bsmodal-width-lg);
}

.web .click-collect-dialog-layout {
  padding: 32px 64px 48px;
}

.web .mbsc-select-input.mbsc-control {
  border-color: var(--ion-color-light);
}

.web .click-collect-dialog-header h3 {
  font-size: 22px;
  text-transform: uppercase;
}

.web .mbsc-fr-popup {
  left: 50%;
}

/* .web .content-spinner {
	background-color: transparent;
} */

.web #main > ion-content.route-click-and-collect .delivery-option-label {
  display: none;
}

.web #main > ion-content.route-click-and-collect .delivery-option-label.web-only {
  display: block;
  top: 64px;
}

.click-collect-description {
  font-size: var(--okx-small-text-font-size);
  font-family: primary-regular;
  font-weight: 300;
  padding: 1px 0;
  opacity: 0.5;
}

.click-collect-small-description{
  font-size: var(--okx-small-text-font-size) !important;
}

.secondary-description {
  color: var(--ion-color-secondary);
}

.bold-description {
  font-weight: 600;
  font-size: var(--okx-sectiontitle-font-size);
}
.click-collect-button{
  position: sticky;
  padding:  0 var(--ion-padding) var(--ion-padding);
  bottom: 0px;
}
.click-collect-button-wrapper {
  /* position: absolute; */
  bottom: 0;
  width: 100%;
}
.click-collect-title {
  padding: 0 var(--ion-padding) var(--ion-padding) ;
}
.web .click-collect-title {
  padding: var(--ion-padding) ;
}
ion-checkbox {
  margin-left: 0;
  --border-color: var(--ion-color-secondary);

}

.click-collect-wrapper {
  height: calc(100% - 50px);
}

.click-collect-layout {
  height: calc(100% - 80px);
  border-radius: var(--okx-box-radius);
}

.click-collect-page {
  height: 100% !important;
}



.click-collect-locations {
  height: 98%;
  padding: 0 var(--ion-padding);
  padding-bottom: 110px;
}

.web .click-collect-locations {
  height: 86%;
}
.click-collect-locations ion-list {
  overflow-y: auto;
  height: 100%;
}
.click-collect-title-description{
    font-weight: bold;
    font-size: inherit !important;
}
.route-click-and-collect .ion-padding > .absolute-content{
  padding: 0;
}