/* behavior */

.item-details-image {
  position: absolute;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  /* next line is portrait-only */
  height: 100vw;
  background: transparent none no-repeat 50% 0;
  background-size: cover;
}

.item-details-card-header h2,
.item-details-card-header h3,
.item-details-card-header h4 {
  margin-top: 0;
}

.item-details-card .okx-puller {
  border-bottom: 6px solid var(--ion-color-light);
}

.item-details-actions {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  z-index: 10;
  background-color: var(--ion-color-white);
}
.bundle-modifiers > *{
  flex: 1;
}
.item-details-group-wrapper>ion-radio-group>div:not(.bundle-modifiers-wrapper),.item-details-group-wrapper>div:not(.bundle-modifiers-wrapper),.bundle-modifiers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px 0 0;
}
.item-details-group-wrapper>ion-radio-group>div:not(.bundle-modifiers):last-of-type,.item-details-group-wrapper>div:not(.bundle-modifiers):last-of-type {
border: none;
}
.web .item-details-group-wrapper>ion-radio-group>div ion-item,.item-details-group-wrapper>div ion-item{
  flex: 1 1;
  width: unset;
}

.sub-item.last-item +.bundle-modifiers-wrapper .bundle-modifiers{
  border-bottom: 0;
  border-top: 1px solid var(--ion-color-gray);
}
/* end behavior */

/* content reset */

ion-button.item-details-add-to-order {
  margin-top: 0;
  margin-bottom: 0;
  --background: var(--ion-color-secondary);
  --background-activated: rgba(var(--ion-color-tertiary-rgb), 0.2);
  color: var(--ion-color-contrast-secondary);
}

ion-button.item-details-add-to-order.greyed {
  --background: var(--ion-color-gray-tint);
  /* --background: var(--ion-color-secondary); */
  color: var(--ion-color-primary);
}

/* end content reset */

/* design */



.item-details-card {
  background-color: var(--okx-box-wrapper-background);
  border-top-left-radius: var(--ion-card-radius);
  border-top-right-radius: var(--ion-card-radius);
}

.item-details-card-header {
  padding: 10px var(--ion-padding) 10px;
  z-index: 1;
  background: var(--ion-color-white);
}

.item-details-card-header > * {
  font-size: var(--ion-font-size);
}
.item-detals-background{
  background-color: var(--okx-box-wrapper-background);
}
.item-details-card-content {
  background-color: var(--okx-box-wrapper-background);
}
.item-details-card-content .sectiontitle {
  margin: 0;
}

/* .item-details-card-description,
.item-details-card-description p,
.item-details-card-description p span {
  font-size: 12px !important;
  color: var(--ion-color-primary) !important;
} */
.image-placeholder {
  height: calc(var(--ion-padding) * 2 + 30px);
}
.web .image-placeholder {
  height: 50px;
}
span.item-details-card-description {
  display: inline-block;
}
.item-details-card-price {
  font-size: var(--okx-title-font-size);
  padding: 0;
  margin: 0;
  z-index: 1;
  text-transform: capitalize;
}

.item-details-card-content {
  padding: 0 var(--ion-padding);
  margin-bottom: 44px;
}
.web .item-details-card-content{
  margin-bottom: 0;
}
.item-details-actions {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 25px;
  padding: 20px;
}

.item-details-actions-quantity {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-details-remove,
.item-details-add {
  flex: 0 0 40px;
  height: 40px;
  --padding-start: 0;
  --padding-end: 0;
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-light);
  --background: var(--ion-color-white);
  --background-hover: var(--ion-color-light);
  --color: var(--ion-color-primary);
}

.item-details-quantity {
  flex: 0 0 75px;
  text-align: center;
}

.item-details-background {
  /* background: var(--ion-color-gray) url(../../assets/images/logo-main.svg) no-repeat 50% 50%/90% 50%; */
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  /* next line is portrait-only */
  opacity: 0.7;
  height: 100vw;
}

.item-details-product-label {
  line-height: normal;
}

/* .route-item-details .okx-pullup-content {
	padding-bottom: 20px;
}

.route-item-details .native-textarea.sc-ion-textarea-md,
.route-item-details .native-textarea.sc-ion-textarea-ios {
	transform: translate3d(0,0,0) !important;
	-webkit-transform: translate3d(0,0,0) !important;
} */


/* end design */

.web .okx-pullup {
  top: 120px !important;
  bottom: 50px !important;
  transform: none !important;
  left: 50%;
  right: auto;
  width: var(--okx-bsmodal-width-lg);
  margin-left: -200px;
  border-radius: var(--okx-box-radius);
}

.web .notch {
  display: none;
}

.web .okx-pullup-content {
  bottom: 140px !important;
}

.web .item-details-actions {
  left: calc(50%);
  width: 350px;
  margin-left: -175px;
  bottom: 60px;
}

.web .item-details-card-content {
  overflow-y: auto;
  padding: 0 10px 0 0;
}
.item-details-card-content .pill{
  padding: 2px 10px !important;
}


.route-item-details{
  overflow: hidden;
}
/* new item details */

.item-details-header {
  display: flex;
  padding: 30px 15px 15px ;
  position: absolute !important;
  top: 0;
  z-index: 5;
  width: 100%;
}
.item-details-price {
  padding: 0;
}
.item-details-price .normal-text{
  font-size: 16px;

}
.item-details-header.active {
  background-color: var(--okx-background-color);
}

.item-details-header ion-button {
  margin: 0;
  height: 40px;
  width: 40px;
  --border-radius:50%
}

.item-details-description {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 10px;
}

.item-details-info .item-details-name {
  background-color: var(--okx-box-wrapper-background);
  padding: 0 var(--ion-padding) 0;
  position: relative;
}

.item-details-main-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--okx-box-wrapper-background);
}

.sub-item ion-item {
  flex: 0 0 220px;
}
.special-instructions-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 10px;
}

.special-instructions-modal-button {
  display: flex;
  align-items: flex-end;
  flex: 1;
}
.special-instructions-modal-button ion-button {
  width: 100%;
}
.special-instructions-modal .modal-classic-content {
  display: flex;
  flex-direction: column;
}
.web .special-instructions-modal .modal-classic-content {
  justify-content: flex-start !important;
}
.special-instructions-wrapper ion-icon {
  margin-left: 10px;
}
.special-instructions-wrapper ion-button {
  height: fit-content;
  margin: 0;
}

.add-instructions-button {
  --padding-start: 0 !important;
  --padding-end: 0 !important;
}
.special-instructions-modal .modal-wrapper {
  border-radius: 0;
}
.web .special-instructions-modal .modal-wrapper {
  border-radius: var(--border-radius);
}
.item-details-img {
	height: 300px;
	width: 100%;
	overflow: hidden;
	position: relative;
	background-color: var(--ion-color-gray);
}
.item-details-img img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}
.special-instructions-modal {
  --height: 100% !important;
  --width: 100% !important;
}
.web .special-instructions-modal {
  --height: 80% !important;
  --width: 500px !important;
}

.special-instructions-wrapper .normal-text {
  white-space: pre-wrap;
}
/* @media screen and (max-width: 330px) {
  .item-details-card-content .item-details-card-list {
    padding: 0 10px;
  }
  .sub-item ion-item {
    flex: 0 0 180px;
  }
  .item-details-price {
    padding: 0 10px;
  }
} */
.item-details-group-wrapper{
  /* border:1px solid var(--ion-color-gray);
  border-radius:var(--okx-box-radius); */
  overflow: hidden;
  margin-bottom: 20px;
}
.web .item-details-group-wrapper ion-item{
  padding: 0 10px;
}
.item-details-group-wrapper ion-item:last-of-type{
  border-bottom: 0;
}

.click-and-collect-timepicker-wrapper{
  background-color: transparent !important;
  padding: 0!important;
  overflow: hidden;
}
.item-modifier-wrapper{
  padding-left: 20px;
  position: relative;
}
.item-modifier-wrapper > div{
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
}
ion-modal.modal-classic.special-instructions-modal .modal-classic-closer {
  position: absolute;
    right: 10px;
    top: 35px !important;
    padding: 0;
}
ion-modal.special-instructions-modal .modal-wrapper{
  border-radius: 0 !important;
}
.web ion-modal.special-instructions-modal .modal-wrapper{
  border-radius:var(--okx-box-radius) !important;
}
.web ion-modal.modal-classic.special-instructions-modal .modal-classic-closer{
  right: 15px;
  top: 35px !important;
}
.special-instructions-modal .modal-classic-content .title{
  margin-bottom: 20px;
}
.special-instructions-modal > .modal-wrapper > .ion-page .modal-classic-closer ion-icon {
  height: 25px;
  width: 25px;
  right: 10px !important;
  top: 10px;
}
.accordian-button{
  display: flex;
  align-items: center;
  margin: 5px 0;
  cursor: pointer;

}
.accordian-button ion-icon{
  margin-left: 10px;
  font-size: 20px;
}
.nutrition-table {
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
  margin-bottom: 15px;
}
.nutrition-table th{
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
}
.nutrition-table td, .nutrition-table th {
  border: 1px solid var(--ion-color-gray);
  text-align: start;
  padding: 5px;
  font-size: var(--ion-font-size);
}
.item-details-name::before {
	content: '';
	height: 30px;
	background-color: var(--okx-box-wrapper-background);
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	position: absolute;
	top: -28px;
	left: 0;
	z-index: 5;
	width: 100%;
}
.item-details-actions .incrementer,.price-increment-content .incrementer {
	flex: 0 0 85px;
	padding: 0 10px;
	border-radius: 25px;
	border: 1px solid var(--ion-color-gray);
  max-width: 120px;
  min-width: 120px;

  height: 44px;
}
.price-increment-content .incrementer-quantity-value{
  color: var(--okx-default-text-color) !important;
}
.item-content-actions{
  align-items: baseline;
}
.route-order .item-content-actions .incrementer,
.route-order-summary .item-content-actions .incrementer {
	display: flex;
}
.item-content-actions .incrementer {
	flex: 0 0 40px;
	padding: 5px;
	border-radius: 25px;
	border: 1px solid var(--ion-color-gray);
	display: none;
}
.item-content-actions .incrementer-quantity-solo,
.item-content-actions .incrementer-quantity {
	flex-basis: 15px;
	margin: 0 10px !important;
	padding: 0 !important;
	display: flex;
}
.item-content-actions .incrementer-quantity:not(.incrementer-quantity-solo) .incrementer-quantity-value {
	display: none;
}
.item-content-actions .incrementer-decrease {
  --background: var(--ion-color-secondary);
}
.route-order .incrementer ion-button {
  font-size: var(--okx-font-size);
  height: auto;
}
.default-image img {
  height: 90%;
  width: 100%;
  object-fit: contain;
}
.item-details-group-name {
  font-size: var(--okx-subtitle-font-size);
  color: var(--okx-default-text-color);
}
.item-details-add-to-order-text {
  display: flex;
  width: 100%;
  justify-content: space-between;
}