/* /* .order-details {
	background: transparent url(../../assets/images/order-completed-bgd.jpg) no-repeat 100% 125%;
	background-size: 100% auto;
	z-index: 1;
} */

.order-status {
  width: auto;
  display: inline-block;
  font-family: var(--ion-font-family);
  font-size: var(--okx-small-text-font-size);
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid var(--ion-color-primary);
  padding: 3px 8px;
  margin-bottom: 5px;
  line-height: 1;
}


.order-details ion-grid {
  padding-inline-start: 0;
  padding-inline-end: 0;
}

.order-details ion-col, .history-details ion-col {
  padding: 2px 0;
}

.order-details .okx-box-footer {
  border-top: 2px solid var(--okx-border-color);
  padding-left: 0;
  margin-left: 0;
  padding-right: 0;
  margin-right: 0;
}

.order-details .okx-box-footer ion-row {
  border-bottom: 0 none;
}

.order-details .okx-box-header-grid ion-row:nth-of-type(1n) {
  border: 0;
}

.order-details .okx-box-header-grid ion-row {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}

.order-details .okx-box-header-grid ion-row ion-col:nth-of-type(2) {
  display: flex;
  justify-content: flex-end;
}

.order-details .okx-box-header-grid ion-row ion-col:nth-of-type(1) ion-text {
  white-space: nowrap;
}



.web ion-content.route-order-completed {
  background-color: #fff;
  /* background-image: url(../../assets/images/dashboard-03.jpg); */
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: cover;
}

.web #main > ion-content.route-order-completed > .ion-padding {

}

.web #main > ion-content.route-order-completed > .ion-padding > .absolute-content.order-details {
  background: none;
  overflow-y: auto;
  padding: 0;
}
.web #main > ion-content.route-order-completed > .ion-padding > .absolute-content.order-details > div{
  padding: var(--ion-padding);
}

.web
  #main
  > ion-content.route-order-completed
  > .ion-padding
  > .absolute-content.order-details:after {
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web
  #main
  > ion-content.route-order-completed
  > .ion-padding
  > .absolute-content.order-details
  > div.scrollable-y {
  height: 100%;
}

.web .order-details .okx-box,
.web .order-details .okx-box-header {
  border-color: var(--okx-border-color);
  border-width: 1px;
}

.web .order-details .okx-box-header-grid ion-row:not(:last-child) ion-col {
  /* padding: 7px 5px 0 5px; */
}

.web .order-details .okx-box-header-grid ion-row:first-of-type ion-col {
  margin-top: 5px;
}

.web .order-details .okx-box-header-grid ion-row:last-of-type ion-col {
  padding-bottom: 7px;
}

.web .order-details .okx-box-header-grid ion-row ion-col:nth-of-type(odd) strong {
  font-weight: normal;
}

.web .order-details .okx-box-header-grid ion-row ion-col:nth-of-type(even) {
  text-align: right;
  font-weight: bold;
}

.order-content-details{
  display: flex;
  align-items: center;
}
.order-content-details > div:first-of-type{
  flex: 1;
}
.order-content-details .order-status-wrapper{
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.order-status-wrapper > div{
  padding: 2px 15px;
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
  border-radius: 15px;
  text-transform: capitalize;
  font-size: var(--okx-small-text-font-size)
;
}