:root {
  --voucher-red: #6e2b13;
  --voucher-black: #000;
}


.voucher.green .voucher-content {
  background-color: var(--ion-color-primary);
}

.voucher.red .voucher-content {
  background-color: var(--voucher-red);
}

.voucher.black .voucher-content {
  background-color: var(--voucher-black);
}

.voucher-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .voucher-content::before,
.voucher-content::after {
  display: block;
  content: '';
  position: absolute;
  top: 8px;
  bottom: 0;
  width: 20px;
  background: radial-gradient(#fff 0px, #fff 6px, transparent 7px, transparent);
  background-size: 20px 20px;
} */

/* .voucher-content::before {
  left: -9px;
}

.voucher-content::after {
  right: -9px;
} */

/* .voucher-content div {
  color: #fff;
} */

.voucher-spacer {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 20px;
  min-width: 20px;
}

.voucher-info,
.voucher-stamp {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  text-align: center;
}

.voucher-stamp {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 85px;
  min-height: 112px;
  min-width: 85px;
  position: relative;
}

.voucher-stamp::after {
  display: block;
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 70px;
  height: 70px;
  margin: -38px 0 0 -38px;
  border-radius: 50%;
  border: 3px #fff solid;
  background: transparent url(../../assets/images/social-icon.svg) no-repeat 50% 50%;
  background-size: 40px 40px;
}

.voucher-card-wrapper {
  display: flex;
  align-items: center;
}
.voucher-title-wrapper {
  width: 50%;
}
.voucher-title-wrapper .title,.voucher-title-wrapper .normal-text {
  color: var(--ion-color-primary-contrast);
  text-align: start;

}
.voucher:nth-child(even) .voucher-title-wrapper .title, .voucher:nth-child(even) .voucher-title-wrapper .normal-text {
  color: var(--ion-color-light-contrast);

}

.voucher-description {
  font-size: 8px;
}
ion-modal .voucher-title-wrapper{
  width: 70%;
}

.voucher-bar {
  display: flex;
  align-items: center;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.voucher-bar > div:first-child,
.voucher-bar > div:last-child {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}

.voucher-bar > div:first-child {
  margin: 0 10px;
}
.web .route-vouchers .absolute-content {
  padding: var(--ion-padding) 0 60px;
}
.route-vouchers .absolute-content{
  padding-bottom: 60px;
  padding-top: 0;
}
.voucher-bar > div:last-child {
  text-align: right;
  padding-right: 12px;
}

.voucher-bar ion-button {
  margin: 0;
  --border-width: 1px;
  --border-color: rgba(var(--ion-color-gray-rgb), 0.5);
  color: var(--ion-color-secondary);
  font-weight: bold;
  text-transform: uppercase;
  height: 30px !important;
}
.voucher-bar {
  padding: 5px 0;
}
.voucher-bar p {
  font-size: var(--okx-small-text-font-size);
  font-weight: 600;
  text-align: start;
}

ion-modal.modal-classic.voucher-modal {
  --width: 85%;
  --height: 70%;
  --border-radius: 10px;
}
ion-modal.modal-classic.voucher-modal .modal-classic-content{
  padding: 0;
}
ion-modal.modal-classic.voucher-modal .modal-classic-wrapper {
  padding: 0;
}

ion-modal.modal-classic.voucher-modal .modal-classic-header {
  /* background-color: var(--ion-color-secondary);
  padding-left: 20px;
  border-bottom: 2px solid var(--ion-color-tertiary); */
}

ion-modal.modal-classic.voucher-modal .modal-classic-header h3 {
  /* color: var(--ion-color-white);
  font-weight: bold;
  text-transform: uppercase;
} */
}
ion-modal.modal-classic.voucher-modal .modal-classic-closer {
  position: absolute;
  right: var(--ion-padding);
  top: 35px !important;
  padding: 0;
  z-index: 5;
}
.web ion-modal.modal-classic.voucher-modal .modal-classic-closer{
  right: 15px;
  top: 10px;
}
.voucher-modal .modal-classic-content .title{
  margin-bottom: 20px;
}
.voucher-modal > .modal-wrapper > .ion-page .modal-classic-closer ion-icon {
  height: 25px;
  width: 25px;
  right: 10px !important;
  top: 10px;
}
.voucher-modal-content {
  padding: 0 30px;
}

.modal-classic-content > div > img {
  object-fit: cover;
  object-position: center;
}
.voucher svg,.reward svg {
  height: 100%;
  width: 100%;
  fill: var(--ion-color-primary);
}
.voucher:nth-child(even) svg,.reward:nth-child(even) svg {
  fill: var(--ion-color-light);
}

.voucher-card-content {
  position: absolute;
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  width: 100%;
  align-items: center;
}
.voucher-card-content img {
  height: 100%;
  width: auto;
}

#main
  > ion-content.route-vouchers
  > .ion-padding
  .absolute-content
  .voucher-modal-content
  > ion-text
  > span {
  font-size: 23px;
}

#main > ion-content.route-vouchers > .ion-padding .absolute-content ion-item {
  --border-color: var(--ion-color-light);
}

#main > ion-content.route-vouchers > .ion-padding .absolute-content .inline-input > ion-text {
  flex: 0 0 25%;
}

.voucher-modal-qr {
  background-size: contain;
  width: 100%;
  height: 200px;
}

.voucher-modal svg {
  fill: var(--ion-color-secondary);
}
.voucher-modal .voucher-card-wrapper {
  position: relative;
  margin: 0 30px;
}
.voucher-modal .ion-page {
  overflow: unset;
}
.web .voucher-modal .voucher-card-wrapper ion-text {
  font-size: 12px;
}
.web .voucher-modal .voucher-card-wrapper .title {
  font-size: 20px;
}
.web .static.route-vouchers {
  background: transparent none;
}
.web .voucher{
  margin: 10px 0 0;
}


.web #main > ion-content.route-vouchers > .ion-padding .absolute-content {
  border-radius: inherit;
  overflow: hidden;
}
.web #main > ion-content.route-vouchers > .ion-padding {
  background-color: #fff;
}

.web .clickup {
  position: absolute;
}

.voucher{
  height: 150px;
  overflow: hidden;
  border-radius: var(--okx-box-radius);
  position: relative;
  margin-top: 10px;
  background-size: cover;
  background-position: center;
  padding: 5px 10px;
  display: flex;
  justify-content: flex-end !important;
  align-items: flex-start !important;
  flex-direction: column;

}

.voucher{
  cursor: pointer;
}
.voucher .subtitle{
  color: var(--ion-color-white);
  z-index: 5;
  
}
.restaurant-logo-voucher{
  background-image: url('../../assets/images/logo-white.png');
  height: 35px;
  width: 35px;
  background-size: cover;
  margin-bottom: 5px;
}
.voucher > *{
  z-index: 5;
}


.voucher-modal > .modal-wrapper > .ion-page{
  min-width: 100%;
  overflow: auto;
  background-color: var(--okx-background-color);
  justify-content: flex-start;
}
.voucher-modal > .modal-wrapper > .ion-page .modal-classic-content
{
  padding: 30px var(--ion-padding) var(--ion-padding);

}
.voucher-modal > .modal-wrapper{
  height: 100%;
    width: 100%;
    border-radius: 0 !important;
    text-align: center;
    overflow: unset;
}
.web .voucher-modal > .modal-wrapper{
}
.web .voucher-modal > .modal-wrapper{
  max-width: var(--okx-bsmodal-width-md);
  width: 100%;
  border-radius: var(--okx-box-radius) !important;

  height: 85%;
  overflow: hidden;
}
.voucher-description {
	display: flex;
	justify-content: space-between;
	background-color: rgb(128,128,128,0.2);
	padding: 20px 10px 5px;
	margin-top: -15px;
	border-radius: 0 0 var(--okx-box-radius) var(--okx-box-radius);
}